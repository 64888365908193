#live-schedule-page {
  ion-fab-button {
    --background: var(--ion-color-step-150, #fff);
    --background-hover: var(--ion-color-step-200, #f2f2f2);
    --background-focused: var(--ion-color-step-250, #d9d9d9);

    --color: var(--ion-color-primary, #3880ff);
  }

  /*
   * Material Design uses the ripple for activated
   * so only style the iOS activated background
   */
  .ios ion-fab-button {
    --background-activated: var(--ion-color-step-250, #d9d9d9);
  }

  ion-item-sliding.track-ionic ion-label {
    border-left: 2px solid var(--ion-color-primary);
    padding-left: 10px;
  }
  ion-item-sliding.track-asamblea ion-label {
    border-left: 2px solid var(--ion-color-asamblea);
    padding-left: 10px;
  }
  ion-item-sliding.track-presentacion ion-label {
    border-left: 2px solid var(--ion-color-presentacion);
    padding-left: 10px;
  }
  ion-item-sliding.track-taller ion-label {
    border-left: 2px solid var(--ion-color-taller);
    padding-left: 10px;
  }
  ion-item-sliding.track-conferencia ion-label {
    border-left: 2px solid var(--ion-color-conferencia);
    padding-left: 10px;
  }
  ion-item-sliding.track-design ion-label {
    border-left: 2px solid var(--ion-color-design);
    padding-left: 10px;
  }
  ion-item-sliding.track-simposio ion-label {
    border-left: 2px solid var(--ion-color-simposio);
    padding-left: 10px;
  }
  ion-item-sliding.track-intervalo ion-label {
    border-left: 2px solid var(--ion-color-intervalo);
    padding-left: 10px;
  }
  ion-item-sliding.track-plenaria ion-label {
    border-left: 2px solid var(--ion-color-plenaria);
    padding-left: 10px;
  }
  ion-item-sliding.track-posters ion-label {
    border-left: 2px solid var(--ion-color-posters);
    padding-left: 10px;
  }
}
