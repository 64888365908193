#session-detail-page {
  .session-track-ionic {
    color: var(--ion-color-primary);
  }

  .session-track-asamblea {
    color: var(--ion-color-asamblea);
  }

  .session-track-presentacion {
    color: var(--ion-color-presentacion);
  }

  .session-track-taller {
    color: var(--ion-color-taller);
  }

  .session-track-conferencia {
    color: var(--ion-color-conferencia);
  }

  .session-track-design {
    color: var(--ion-color-design);
  }

  .session-track-simposio {
    color: var(--ion-color-simposio);
  }

  .session-track-intervalo {
    color: var(--ion-color-intervalo);
  }

  .session-track-plenaria {
    color: var(--ion-color-plenaria);
  }

  .session-track-posters {
    color: var(--ion-color-posters);
  }

  .show-favorite {
    position: relative;
  }

  .icon-heart-empty {
    position: absolute;
    top: 5px;
    right: 5px;
    transform: scale(1);
    transition: transform 0.3s ease;
  }

  .icon-heart {
    position: absolute;
    top: 5px;
    right: 5px;
    transform: scale(0);
    transition: transform 0.3s ease;
  }

  .show-favorite .icon-heart {
    transform: scale(1);
  }

  .show-favorite .icon-heart-empty {
    transform: scale(0);
  }

  h1 {
    margin: 0;
  }
}
